

function App() {
  return (
    <div>
      <div>
        <nav className="navbar shadowJaluPrimary fixed-top bg-gradient-red">
          <a className="navbar-brand" href="#">
            <img src="/assets/img/logo-red-backgroud-white.png" width="48" height="48" alt="" />
          </a>
          <a href="https://rumah.sijalu.id" className="text-white">
            Masuk / Daftar
          </a>
        </nav>
        <div className="bannerSection">
          <div className={"bannerItem d-md-flex justify-content-between"}>
            <div className={"d-flex px-5 text-white col-lg-6 align-items-center"}>
              SiJaLu Hadir Untuk Membantu Mengelola Aktifitas Kamu Dimanapun Dan Kapanpun
            </div>
            <div className={"d-flex col-lg-6 align-items-end justify-content-around justify-content-md-end"}>
                <img src="/assets/img/avatarJalu.png" width="250" height="250" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className=" d-md-flex py-4">
        <div className="align-self-center col-md-4  justify-content-center d-flex">
          <h3>Apa Itu SiJaLu ?</h3>
        </div>
        <div className="d-md-none align-self-center justify-content-center col-md-4 d-flex">
          <img src="/assets/img/jalu.png" width="150" />
        </div>
        <div className="col-md-4">
          <h4>SiJaLu</h4>
          <p>Adalah aplikasi yang dibuat untuk mengelola semua aktifitas maupun itu kehadiran ( Absensi ), pekerjaan, keuangan dan yang lainnya. Dibuat agar lebih mudah digunakan oleh siapapun, kapan pun dan dimanapun</p>
        </div>
        <div className="d-none align-self-center justify-content-center col-md-4 d-md-flex">
          <img src="/assets/img/jalu.png" width="150" />
        </div>
      </div>
      <div className=" d-md-flex bg-white p-0 m-0  w-100 py-4">
        <div className="col-md-4 py-3">
          <div className="align-self-center justify-content-center d-flex">
            <img src="/assets/img/devices.png" width="70%" />
          </div>
          <div className=" mt-3">
            <a className="justify-content-center d-flex" href="https://play.google.com/store/apps/details?id=id.octaaldino.jalu" target="_blank" >
              <img src="/assets/img/google-play-badge.png" width="50%" />
            </a>
          </div>
        </div>
        <div className="col-md-8 pr-5">
          <h4>Mengapa SiJaLu ?</h4>
          <p>SiJaLu saat ini memiliki fitur fitur yang dapat digunakan melalui web dan mobile. Semua fitur dapat dikelola oleh pengguna sesuai kebutuhannya, salah satunya metode login yang dapat dikelola langsung oleh pengguna. Dapat dikombinasikan dengan kode yang berlaku di waktu tertentu dan dikirimkan langsung melalui Whatsapp. Sehingga keamanan lebih terjaga dan tepat hanya pada pengguna.
          <br /> <br />
          SiJaLu terus membuat fitur yang akan dibutuhkan oleh pengguna dalam menjalani aktifitas apapun profesinya</p>
        </div>
      </div>
      
      <div className="p-0 m-0  w-100 py-4">
        <div className="py-3 justify-content-center d-flex">
          <h3 className="text-center">Yuk Daftar SiJaLu Sekarang Gratis</h3>
        </div>
        <div className="justify-content-center d-flex">
          <a href="https://rumah.sijalu.id/signup" className="btn btn-success">
              Daftar Sekarang
          </a>
        </div>
      </div>

      <div className="footerJalu text-white d-lg-flex justify-content-between">
        <div className="col-lg-6 px-4 pt-4 pb-3 d-flex">
          <div className="align-self-center justify-content-center d-flex">
            <img src="/assets/img/logo-blue-backgroud-white.png" width="100" height="100" alt="" />
          </div>
          <div className="mx-3">
            <h4>Hak Cipta SiJaLu  {new Date().getFullYear()}</h4>
            <p>Hak cipta meliputi seluruh konten ( Foto, Gambar, Tulisan ) yang ada pada website ini adalah Milik SiJalu.Id. Jika ingin menggunakannya dapat memberitahu SijJalu.Id melalui Email ataupun informasi kontak yang terdapat pada Website Ini</p>
          </div>
        </div>
        <div className="col-lg-4 px-4 pt-4 pb-3">
          <div>
            <ul>
              <li>Email : <a href="mailto:cs@jalu.id" className="text-white">cs@sijalu.id</a> </li>
              {/* <li>Whatsapp Bisnis : <a href="https://wa.me/6287875878767" className="text-white">SiJaLu</a></li> */}
              <li>Instagram : <a href="https://instagram.com/sijaluid" className="text-white" >sijaluid</a></li>
              {/* <li>Twitter : sijaluid</li> */}
            </ul>
          </div>
          <div className="justify-content-center justify-content-lg-end d-flex">
            <a href="https://play.google.com/store/apps/details?id=id.octaaldino.jalu" target="_blank" >
              <img src="/assets/img/google-play-badge.png" width="100" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
